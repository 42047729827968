/* eslint-disable prefer-const,no-alert,prefer-template,prefer-destructuring */
import mock from '@/@fake-db/mock'
import jwt from 'jsonwebtoken'
// import db from 'firebase'
import firebase from 'firebase/app'

const data = {
  users: [
    {
      id: 1,
      fullName: 'John Doe',
      username: 'johndoe',
      password: 'admin',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/13-small.png'),
      email: 'admin@demo.com',
      role: 'admin',
      ability: [
        {
          action: 'manage',
          subject: 'all',
        },
      ],
      extras: {
        eCommerceCartItemsCount: 5,
      },
    },
    {
      id: 2,
      fullName: 'Jane Doe',
      username: 'janedoe',
      password: 'client',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/1-small.png'),
      email: 'client@demo.com',
      role: 'client',
      ability: [
        {
          action: 'read',
          subject: 'ACL',
        },
        {
          action: 'read',
          subject: 'all',
        },
      ],
      extras: {
        eCommerceCartItemsCount: 5,
      },
    },
    {
      id: 3,
      fullName: 'Elux',
      username: 'elux',
      password: '123',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/111.png'),
      email: 'elux@elux.com',
      role: 'client',
      ability: [
        {
          action: 'read',
          subject: 'ACL',
        },
        {
          action: 'read',
          subject: 'all',
        },
      ],
      extras: {
        eCommerceCartItemsCount: 0,
      },
    },
  ],
}

// ! These two secrets shall be in .env file and not in any other file
const jwtConfig = {
  secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
  refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
  expireTime: '10m',
  refreshTokenExpireTime: '10m',
}

mock.onPost('/jwt/login').reply(request => {
  const { email, password } = JSON.parse(request.data)
  // eslint-disable-next-line no-unused-vars
  return new Promise(((resolve, reject) => {
    if (true) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          console.log('Successfully logged in')
          // eslint-disable-next-line prefer-destructuring
          const uid = firebase.auth().currentUser.uid
          // console.log('UID' + uid)
          const db = firebase.firestore()
          // const sx = this.$firebase.firestore()
          db.collection('User')
            .doc(uid)
            .get().then(doc => {
              // console.log(doc.data())
              // console.log('USERNAME' + doc.data().username)
              const user = {
                id: uid,
                fullName: '',
                username: doc.data().username,
                password: '',
                // eslint-disable-next-line global-require
                avatar: require('@/assets/images/avatars/1-small.png'),
                email: doc.data().email,
                role: doc.data().role,
                ability: doc.data().ability,
                extras: {
                  eCommerceCartItemsCount: 5,
                },
              }
              // console.log(doc.data())
              const accessToken = jwt.sign({ id: uid }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
              const refreshToken = jwt.sign({ id: uid }, jwtConfig.refreshTokenSecret, {
                expiresIn: jwtConfig.refreshTokenExpireTime,
              })
              const userData = { ...user }
              // console.log(userData)
              // delete userData.password

              const response = {
                userData,
                accessToken,
                refreshToken,
              }
              return resolve([200, response])
            })
            .catch(error => {
              console.log('Error getting document:', error)
            })
        })
        .catch(() => {
          console.log('Email or Password is Invalid')
          let error = {
            email: ['Email or Password is Invalid'],
          }
          return resolve([400, { error }])
        })
    }
  }))
  // let error = {
  //   email: ['Something went wrong'],
  // }
  // const user = data.users.find(u => u.email === email && u.password === password)
  // console.log(`EMAIL:::${email}`)
  // console.log(`password${password}`)
  // if (user) {
  //   try {
  //     const accessToken = jwt.sign({ id: user.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
  //     const refreshToken = jwt.sign({ id: user.id }, jwtConfig.refreshTokenSecret, {
  //       expiresIn: jwtConfig.refreshTokenExpireTime,
  //     })
  //
  //     const userData = { ...user }
  //
  //     delete userData.password
  //
  //     const response = {
  //       userData,
  //       accessToken,
  //       refreshToken,
  //     }
  //
  //     return [200, response]
  //   } catch (e) {
  //     error = e
  //   }
  // } else {
  //   error = {
  //     email: ['Email or Password is Invalid'],
  //   }
  // }

  // return [400, { error }]
})

mock.onPost('/jwt/register').reply(request => {
  const { username, email, password } = JSON.parse(request.data)
  // console.log(email)
  return new Promise(((resolve, reject) => {
    if (true) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              // const auth = firebase.auth()
              // auth.sendPasswordResetEmail('jemkein@gmail.com').then(() => {
              //   console.log('Email Sent')
              //   // eslint-disable-next-line no-unused-vars
              // }).catch(error => {
              //   // An error happened.
              // })
              console.log('Successfully logged in')
              const userData = {
                id: firebase.auth().currentUser.uid,
                email,
                password,
                username,
                fullName: '',
                avatar: null,
                role: 'client',
                ability: [
                  {
                    action: 'read',
                    subject: 'ACL',
                  },
                  {
                    action: 'read',
                    subject: 'all',
                  },
                ],
              }

              const db = firebase.firestore()
              const usersRef = db.collection('User')
              usersRef.doc(firebase.auth().currentUser.uid).set({
                email,
                username,
                role: 'client',
                Package: 'None',
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                twitchname: 'null',
                Botpanel: {
                  Active: false,
                  Autostart: true,
                  CategoryChatbot: true,
                  Chatbot: true,
                  Viewbot: true,
                  Views: 10,
                },
                ability: [
                  {
                    action: 'read',
                    subject: 'ACL',
                  },
                  {
                    action: 'read',
                    subject: 'all',
                  },
                ],
              })// create the document
                .then(() => {
                  // console.log('INREGISTERTHEN')
                  const uid = firebase.auth().currentUser.uid
                  data.users.push(uid)

                  const user = { ...userData }

                  const newAccessToken = jwt.sign({ id: uid }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
                  const newRefreshToken = jwt.sign({ id: uid }, jwtConfig.refreshTokenSecret, {
                    expiresIn: jwtConfig.refreshTokenExpireTime,
                  })
                  delete user.password
                  const response = {
                    userData: user,
                    accessToken: newAccessToken,
                    refreshToken: newRefreshToken,
                  }
                  // console.log(response)
                  return resolve([200, response])
                })
              // Add user id+
            })
            .catch(error => {
              console.log(error)
              // eslint-disable-next-line prefer-promise-reject-errors
              return reject('ACHTUNG')
            })
        })
        .catch(error1 => {
          let emailexist2 = false
          let isUsernameAlreadyInUse = false
          let passwordshort = false
          const errorCode = error1.code
          const errorMessage = error1.message
          if (errorCode === 'auth/weak-password') {
            passwordshort = true
          } else if (errorCode === 'auth/email-already-in-use') {
            emailexist2 = true
          } else if (errorCode === 'auth/credential-already-in-use') {
            isUsernameAlreadyInUse = true
          } else {
            alert(errorMessage)
          }

          // console.log(error1)

          const error = {
            password: passwordshort ? ['Your password is too weak'] : null,
            email: (() => {
              if (!email) return ['Please enter your email.']
              if (emailexist2) return ['This email is already in use.']
              return null
            })(),
            username: (() => {
              if (!username) return ['Please enter your username.']
              if (isUsernameAlreadyInUse) return ['This username is already in use.']
              return null
            })(),

          }
          console.log(error)
          // eslint-disable-next-line prefer-promise-reject-errors
          return resolve([400, { error }])
        })
    }
  }))
})

mock.onPost('/jwt/refresh-token').reply(request => {
  const { refreshToken } = JSON.parse(request.data)

  try {
    const { id } = jwt.verify(refreshToken, jwtConfig.refreshTokenSecret)

    const userData = { ...data.users.find(user => user.id === id) }

    const newAccessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn })
    const newRefreshToken = jwt.sign({ id: userData.id }, jwtConfig.refreshTokenSecret, {
      expiresIn: jwtConfig.refreshTokenExpireTime,
    })

    delete userData.password
    const response = {
      userData,
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    }

    return [200, response]
  } catch (e) {
    const error = 'Invalid refresh token'
    return [401, { error }]
  }
})
