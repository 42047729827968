import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import firebase from 'firebase'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

/* Import the firebase SDK and extend with firestore */

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
require('firebase/firestore')

/* Paste your firebase configuration below */
const firebaseConfig = {
  apiKey: 'AIzaSyC0GBS8a7XaEprLekSd0hFwUIGDLsxlGs4',
  authDomain: 'geminos-4dedd.firebaseapp.com',
  databaseURL: 'https://geminos-4dedd-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'geminos-4dedd',
  storageBucket: 'geminos-4dedd.appspot.com',
  messagingSenderId: '10855192662',
  appId: '1:10855192662:web:2af72f2d7c783d68f503f7',
  measurementId: 'G-4MR49KPZE4',
}

/* Initialize firebase with your configuration */
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
} else {
  firebase.app() // if already initialized, use that one
}
firebase.analytics()
Vue.prototype.$functions = firebase.functions().httpsCallable()
Vue.prototype.$analytics = firebase.analytics()

/* Bind firebase to your Vue instance */
Vue.prototype.$firebase = firebase

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
