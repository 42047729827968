export default {
  namespaced: true,
  state: {
    chatLog: [],
  },
  getters: {
  },
  mutations: {
  },
  actions: {},
}
