import { $themeBreakpoints } from '@themeConfig'
import firebase from 'firebase'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    raidActive: false,
    raidedChannel: 'none',
    shallShowOverlay: false,
    userCollection: null,
    userData: null,
  },
  getters: {
    // getUserCollection: state => {
    //   if (state.userCollection === null) {
    //     console.log('isNUll')
    //     const sx = firebase.firestore()
    //     sx.collection('System').doc('NGROK').get().then(result => {
    //       state.userCollection = result.data()
    //       console.log(result.data())
    //       return state.userCollection
    //     })
    //     // return state.userCollection
    //   }
    //   return state.userCollection
    // },
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATEUSERCOLLECTION(state) {
      const sx = firebase.firestore()
      // eslint-disable-next-line no-unused-vars
      sx.collection('System').doc('NGROK').get().then(result => {
        state.userCollection = 'test'
      })
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {},
}
