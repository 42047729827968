export default [
  {
    path: '/dashboard/',
    name: 'Dashboard',
    component: () => import('@/views/dashboard/Ecommerce.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/admindashboard/',
    name: 'Admin-Dashboard',
    component: () => import('@/views/pages/adminDashboard/adminDashboard.vue'),
    meta: {
      resource: 'ACL',
      action: 'manage',
      pageTitle: 'Admin-Dashboard',
      breadcrumb: [
        {
          text: 'Admin-Dashboard',
          // active: true,
        },
      ],
    },
  },
  {
    path: '/followerfabrik/followdashboard',
    name: 'Admin-Dashboard',
    component: () => import('@/views/pages/followerFabrik/followerDashboard.vue'),
    meta: {
      pageTitle: 'Follow-Dashboard',
      breadcrumb: [
        {
          text: 'Follow-Dashboard',
          // active: true,
        },
      ],
    },
  },
  {
    path: '/followerfabrik/orderdashboard',
    name: 'Admin-Dashboard',
    component: () => import('@/views/pages/followerFabrik/orderDashboard.vue'),
    meta: {
      pageTitle: 'Order-Dashboard',
      breadcrumb: [
        {
          text: 'Order-Dashboard',
          // active: true,
        },
      ],
    },
  },
  {
    path: '/followerfabrik/videodashboard',
    name: 'Admin-Dashboard',
    component: () => import('@/views/pages/followerFabrik/videoDashboard.vue'),
    meta: {
      pageTitle: 'Video-Dashboard',
      breadcrumb: [
        {
          text: 'Video-Dashboard',
          // active: true,
        },
      ],
    },
  },
  {
    path: '/followerfabrik/viewerdashboard',
    name: 'Admin-Dashboard',
    component: () => import('@/views/pages/followerFabrik/viewerDashboard.vue'),
    meta: {
      pageTitle: 'Viewer-Dashboard',
      breadcrumb: [
        {
          text: 'Viewer-Dashboard',
          // active: true,
        },
      ],
    },
  },
  {
    path: '/pages/VOD',
    name: 'VOD',
    component: () => import('@/views/pages/VOD/FormRepeater.vue'),
    meta: {
      pageTitle: 'Videos on demand + Clips',
      breadcrumb: [
        {
          text: 'VOD',
          // active: true,
        },
      ],
    },
  },
  {
    path: '/pages/follower',
    name: 'Follower-Panel',
    component: () => import('@/views/pages/follower/follower-panel.vue'),
    meta: {
      pageTitle: 'Follower Panel',
      breadcrumb: [
        {
          text: 'Follower Panel',
          // active: true,
        },
      ],
    },
  },
  {
    path: '/pages/raid',
    name: 'Raid-Panel',
    component: () => import('@/views/pages/raid/raidPanel.vue'),
    meta: {
      pageTitle: 'Raid Panel',
      breadcrumb: [
        {
          text: 'Raid Panel',
          // active: true,
        },
      ],
    },
  },
  {
    path: '/pages/Panel',
    name: 'panel',
    component: () => import('@/views/pages/Panel/Switch.vue'),
    meta: {
      pageTitle: 'Embedding-Panel',
      breadcrumb: [
        {
          text: 'Adroll-Panel',
          // active: true,
        },
      ],
    },
  },
  {
    path: '/pages/Chatpattern',
    name: 'chatpattern',
    component: () => import('@/views/pages/Chat/FormTag.vue'),
    meta: {
      pageTitle: 'Chatpattern',
      breadcrumb: [
        {
          text: 'Chatpattern',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/Conversation',
    name: 'conversation',
    component: () => import('@/views/pages/Chat/Conversation.vue'),
    meta: {
      pageTitle: 'Conversation',
      breadcrumb: [
        {
          text: 'Conversation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/license',
    name: 'License',
    component: () => import('@/views/pages/license/License.vue'),
    meta: {
      resource: 'ACL',
      action: 'manage',
      pageTitle: 'License',
      breadcrumb: [
        {
          text: 'License',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/enable',
    name: 'Enable',
    component: () => import('@/views/pages/Chat/EnableDisable'),
    meta: {
      pageTitle: 'Enable/Disable',
      breadcrumb: [
        {
          text: 'Enable',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/chatboard',
    name: 'chatboard',
    component: () => import('@/views/pages/Chat/Chatboard'),
    meta: {
      pageTitle: 'Chatboard',
      breadcrumb: [
        {
          text: 'Enable',
          active: true,
        },
      ],
    },
  },
]
