import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  pricing: {
    basicPlan: {
      title: 'Starter',
      img: require('@/assets/images/illustration/Pot1.svg'),
      subtitle: 'A simple start for everyone',
      monthlyPrice: 8,
      yearlyPlan: {
        perMonth: 30,
        totalAnual: 20,
      },
      planBenefits: [
        '15 Live Viewers',
      ],
      popular: false,
    },
    standardPlan: {
      title: 'Headstarter',
      img: require('@/assets/images/illustration/Pot2.svg'),
      subtitle: 'Wanna get a Headstart?',
      monthlyPrice: 15,
      yearlyPlan: {
        perMonth: 70,
        totalAnual: 480,
      },
      planBenefits: [
        '50 Live Viewers',
      ],
      popular: true,
    },
    enterprisePlan: {
      title: 'Pro',
      img: require('@/assets/images/illustration/Pot3.svg'),
      subtitle: 'For everyone who wants to be on the top!',
      monthlyPrice: 25,
      yearlyPlan: {
        perMonth: 130,
        totalAnual: 960,
      },
      planBenefits: [
        '100 Live Viewers',
        // '1000 Followers over 1 Month',
      ],
      popular: false,
    },
    customPlan: {
      title: 'Custom',
      img: require('@/assets/images/illustration/Pot3.svg'),
      subtitle: 'Dominate all others!',
      monthlyPrice: 0,
      yearlyPlan: {
        perMonth: 0,
        totalAnual: 960,
      },
      planBenefits: [
        'Custom Live Viewers',
      ],
      popular: false,
    },
    qandA: [
      {
        question: 'Does my subscription automatically renew?',
        ans:
          'Yes! You can cancel it at any time on sellix via the Link in your Mail or via Mail to support@streamerzone.gg .',
      },
      {
        question: 'How can i pay?',
        ans:
            'You can use PayPal, BTC, ETH, LTC and CreditCard in our Sellix shop. If you want to buy with a different Payment Method, contact us via Discord or E-Mail.',
      },
      {
        question: 'How can I cancel the Subscription?',
        ans:
            'You can cancel your subscription either on PayPal: https://www.paypal.com/myaccount/autopay/  or directly on Sellix: https://customer-portal.sellix.io/!',
      },
      // {
      //   question: 'Am I allowed to modify the item that I purchased?',
      //   ans:
      //     'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.',
      // },
    ],
  },
}
/* eslint-disable global-require */
mock.onGet('/pricing/data').reply(() => [200, data.pricing])
